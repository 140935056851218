import React from "react";

import { Layout, SEO } from "../../../components/structure";
import {
  Button,
  Content,
  Hero,
  HospitalityPremiumUpgrade,
  Testimonial,
  CallToAction,
  HospitalitySplitBlock,
  HospitalityPartnerDashboard,
  HospitalityGrid,
} from "../../../components/blocks";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const HospitalityPremiumUpgradePage = props => {
  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "48-gallery_photos-grYC1bkP.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right-blue.png" }) {
        ...ButtonIcon
      }
      rightArrowIconWhite: file(base: { eq: "arrow-right.png" }) {
        ...ButtonIcon
      }
      airIcon: file(base: { eq: "AirplaneIcon.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clickUpgradeIcon: file(base: { eq: "click-upgrade.png" }) {
        childImageSharp {
          fixed(width: 45, height: 80, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      speedPassProduct: file(base: { eq: "Product-Speed-Pass.png" }) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      partnerDashboardProduct: file(
        base: { eq: "partner-dashboard-mobile-mockup.png" }
      ) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      premiumUpgradeTestimonal: file(
        base: { eq: "GettyImages-903417402-min.jpg" }
      ) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      growthArrow: file(base: { eq: "ArrowClimber.png" }) {
        childImageSharp {
          fixed(width: 500, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      allHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 400, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobileallHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 1000, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      empireHotel: file(base: { eq: "EmpireHotelGroup.jpeg" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      fattalHotel: file(base: { eq: "Fattal.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      grecotelHotel: file(base: { eq: "Grecotel.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      ihgHotel: file(base: { eq: "IHG-NewLogo.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      orchardHotel: file(base: { eq: "Orchid_New_Logo_i6vzc7.jpeg" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      myconianHotel: file(base: { eq: "MyconianCollection.jpeg" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      leonardoHotel: file(base: { eq: "leonardo_red.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      stayCityHotel: file(base: { eq: "StayCityApartHotels.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      villaGroupHotel: file(base: { eq: "TheVillaGroup.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      setaiHotel: file(base: { eq: "TheSetai.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      nyxHotel: file(base: { eq: "NYX.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      herbertHotel: file(base: { eq: "HerbertSamuel.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      whiteLabelIcon: file(base: { eq: "55.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fastestTimeIcon: file(base: { eq: "79.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      riskFreeIcon: file(base: { eq: "163.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      dataDrivenIcon: file(base: { eq: "41.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      FastIntegrationIcon: file(base: { eq: "93.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      provenRevenueIcon: file(base: { eq: "101.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      generateAncillary: file(base: { eq: "generateAncillary.png" }) {
        childImageSharp {
          fixed(width: 58, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      inventoryControl: file(base: { eq: "inventoryControl.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      HeroRightArrowTriColor: file(
        base: { eq: "HorizontalWebArrowTriColour.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const ProductsHeroContainer = styled.div`
    width: 100%;
    max-width: 1400px;
    /*margin: 80px auto;*/
    margin: 80px auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2%;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
    }

    > div {
      flex-basis: 48%;
    }

    .graphic-con {
      width: 100%;
      max-width: 700px;
      height: 300px;

      @media only screen and ${mq.maxMd} {
        align-self: flex-start;
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .title-con {
      h2 {
        white-space: pre-wrap;
        font-family: ${font.family.sherika};
        font-weight: 700;
        font-size: 80px;
        color: ${brand.colors.pg_orange};
        padding-top: 135px;
        @media only screen and ${mq.maxMd} {
          text-align: center;
          font-size: 60px;
        }
      }
    }
  `;

  const CruiseParnters = styled.div`
    /*background: ${brand.colors.pg_primary_light} !important;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px auto;

    .cruise-logo-container {
      width: 95%;
      max-width: 1400px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      @media only screen and ${mq.maxMd} {
        flex-wrap: wrap;
        justify-content: center;
      }

      .logo-con {
        flex-basis: 200px;
      }
    }
    
`;

  const PlatformDetailContainer = styled.div`
    width: 100%;
    background-color: ${brand.colors.pg_blue};

    .inner-icon-container {
      width: 95%;
      max-width: 1020px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 80px 0;
      gap: 40px;

      @media only screen and ${mq.maxMd} {
        width: 80%;
      }
    }

    .platform-headline {
      display: inline-block;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-top: 2rem;

      /*
       .headline-con {
         margin-bottom:0;
       }
       */
    }
  `;

  const LogoGrid = styled.div`
    /*width: 100%;*/
    max-width: 1400px;
    /*margin: 80px auto;*/
    margin: 80px auto;
    margin-top: 0;
    margin-bottom: 0;
    /*padding: 80px 0;*/
    /*display: flex;*/
    text-align: center;

    span {
      padding-top: 40px;
      text-align: center;
      padding-bottom: 80px;
    }

    h2 {
      color: ${brand.colors.pg_primary_dark};
      font-size: 26px;
      width: 100%;
      text-align: center;
      // margin-top:2em;
      @media only screen and (max-width: 400px) {
        font-size: 22px;
      }
    }

    .logo-grid-container {
      align-items: center;
      justify-content: space-between;
      gap: 2%;
      grid-row-gap: 20px;
      display: grid;
      /*grid-gap: 1rem;*/
      grid-template-columns: repeat(6, 1fr);
      padding: 2em;

      @media only screen and ${mq.maxMd} {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        padding: 2em;
      }

      .headline-con {
        p {
          color: #080a12 !important;
        }
      }
    }

    ${props =>
      props.lightGreyBg &&
      css`
        background-color: ${brand.colors.pg_primary_light};
      `}
  `;

  return (
    <Layout>
      <SEO
        title="Hospitality Premium Upgrade | Plusgrade"
        description={
          "Plusgrade accelerates your incredible passenger experiences while generating significant ancillary revenue on every flight."
        }
        keywords={"airline, flight, upgrades"}
      />
      <Content whiteBG>
        <Hero
          title={"Premium Upgrade for\nthe hospitality industry"}
          image={pageQuery["hero"]}
          subtitle={
            "Elevate your guests' stay experience while boosting your revenue with seamless room upgrade options."
          }
          buttonURL={"/industries/hospitality/demo/"}
          buttonLabel={"Get started"}
          buttonIcon={pageQuery["rightArrowIconWhite"]}
          fullBG
        />

        <HospitalityPremiumUpgrade
          title={"Premium Upgrade"}
          copy={
            "Boost revenue by giving your guests the power to upgrade their stay. Grow your ancillary income effortlessly with our automated solution, fully compatible with leading PMS and Booking systems."
          }
          buttonDest={"/industries/hospitality/demo/"}
          icon={pageQuery["premiumIcon"]}
          buttonLabel={"Request a demo"}
          buttonIcon={pageQuery["rightArrowIconWhite"]}
        ></HospitalityPremiumUpgrade>

        <CallToAction
          primaryLightBg
          title={"Powering upselling solutions\nfor hotels across the globe"}
          subtitle={
            "Trusted by hotel properties globally to unlock new revenue\nstreams and deliver extraordinary guest experiences."
          }
          style={{ paddingBottom: "0px" }}
        ></CallToAction>

        <Content secondaryLightBG>
          <LogoGrid lightGreyBg>
            <div className="logo-grid-container">
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["empireHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["fattalHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["grecotelHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["ihgHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["orchardHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["myconianHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["leonardoHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["stayCityHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["villaGroupHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["setaiHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["nyxHotel"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["herbertHotel"]}
              />
            </div>
            <Button
              destination={"/partners/"}
              label={"See all partners"}
              icon={pageQuery["rightArrowIconWhite"]}
              black
              whiteText
              pill
            />
          </LogoGrid>
        </Content>

        <PlatformDetailContainer>
          <div className="inner-icon-container">
            <IconContainer
              whiteOnBlue
              icon={pageQuery["whiteLabelIcon"]}
              headline={"White-label solutions"}
              copy={
                "Hotel-branded solution that provides a seamless customer experience."
              }
            />

            <IconContainer
              whiteOnBlue
              icon={pageQuery["fastestTimeIcon"]}
              headline={"Fastest time-to-market"}
              copy={
                "Start generating incremental revenue in as little as three days."
              }
            />

            <IconContainer
              whiteOnBlue
              icon={pageQuery["riskFreeIcon"]}
              headline={"Risk-free investment"}
              copy={"Zero implementation fees or upfront costs."}
            />

            <IconContainer
              whiteOnBlue
              icon={pageQuery["dataDrivenIcon"]}
              headline={"Data-driven decisioning"}
              copy={
                "Robust business rules and levers designed to optimize your upgrade program."
              }
            />

            <IconContainer
              whiteOnBlue
              icon={pageQuery["FastIntegrationIcon"]}
              headline={"Fast integrations"}
              copy={
                "Easy implementation with your existing PMS and channel management systems."
              }
            />

            <IconContainer
              whiteOnBlue
              icon={pageQuery["provenRevenueIcon"]}
              headline={"Proven revenue growth"}
              copy={
                "Maximize profit with diversified products and revenue streams."
              }
            />
          </div>
        </PlatformDetailContainer>

        <HospitalityPartnerDashboard
          title={"Partner dashboard"}
          copy={
            "Experience total control and crystal clear visibility with our comprehensive partner dashboard. Gain instant access to real-time performance tracking, incremental revenue reports, and manage your product portfolio and upselling initiatives, all while overseeing every order. Never be left wondering about your performance again."
          }
          buttonLabel={"Book a demo"}
          graphicStyle={{ objectPosition: "center top" }}
          image={pageQuery["partnerDashboardProduct"]}
          id="speed-pass1"
          buttonDestination={"/industries/hospitality/demo/"}
          style={{ marginTop: "0px" }}
          productPage
        />

        <Testimonial
          testimonials={[
            "[Premium Upgrade] significantly [increased] our chain's revenues from room upgrades. As it is fully-automated, we don't need to invest any time or resources in the solution - it does it all by itself.--HotelTechReport review",
            "[The product] has helped us … make the client's choice to upgrade so simple. Just to know that each guest receives a personalized email with the opportunity to upgrade their room type by bidding (who doesn't like that?) makes it even more luxurious.--HotelTechReport review",
            "The program helps us a lot to upsell our rooms. Guests automatically get upgrade options by email and frequently use this option. Also it is great working with the team; they offer constant and fast support if I have any questions.--HotelTechReport review",
          ]}
          background={pageQuery["premiumUpgradeTestimonal"]}
          imageStyle={{ objectPosition: "center center" }}
        />

        <Content noMargin>
          <ProductsHeroContainer>
            <div className="graphic-con">
              <GatsbyImage
                fluid={
                  pageQuery["HeroRightArrowTriColor"].childImageSharp.fluid
                }
                imgStyle={{ objectPosition: "right center" }}
              />
            </div>
            <div className="title-con">
              <h2>{"Effortless\nintegration"}</h2>
            </div>
          </ProductsHeroContainer>
        </Content>

        <Content orangeBG>
          <HospitalitySplitBlock
            copy={
              "Transform your hotel operations with our upselling solutions. Designed for seamless integration with your current brand and property management system, our solution automates upselling to supercharge your revenue potential while enhancing your guest experiences. "
            }
            buttonLabel={"See all integrations"}
            graphicStyle={{ objectPosition: "center top" }}
            image={pageQuery["speedPassProduct"]}
            id="speed-pass2"
            buttonDestination={"/industries/hospitality/integrations"}
            style={{ marginTop: "-60px" }}
            productPage
          />
        </Content>

        <CallToAction
          title={"Drive ancillary revenue with\nincredible guest experiences"}
          subtitle={"Contact us today to learn more."}
        >
          <Button
            destination={"/industries/hospitality/demo/"}
            label={"Request a demo"}
            icon={pageQuery["rightArrowIcon"]}
            white
            blueText
            pill
          />
        </CallToAction>
      </Content>
    </Layout>
  );
};

export default HospitalityPremiumUpgradePage;

const IconCon = styled.div`
  ${font.imports.sherika}
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and ${mq.maxMd} {
    flex-basis: 95%;
  }

  .icon-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline {
    font-family: ${font.family.sherika};
    font-weight: 600 !important;
    color: ${brand.colors.pg_orange} !important;
    font-size: 48px !important;
    margin-bottom: 1px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
  }

  p {
    margin-top: 1px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  ${props =>
    props.whiteOrange &&
    css`
      flex-basis: 30%;
      align-items: flex-start;
      flex-wrap: wrap;

      .headline-con {
        text-align: left;
        h4 {
          color: ${brand.colors.pg_primary_dark} !important;
          font-family: ${font.family.sherika};
          font-size: 22px !important;
          font-weight: 700;
          white-space: pre-line;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
        p {
          color: ${brand.colors.pg_primary_dark};
          font-family: ${font.family.sherika};
          font-weight: 400;
          margin: 20px 0;
          min-height: 96px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        .headline {
          min-height: 60px;
          display: flex;
          align-items: center;
          @media only screen and ${mq.maxMd} {
            text-align: center;
            justify-content: center;
          }
        }
      }
    `}

  ${props =>
    props.whiteOnBlue &&
    css`
      flex-basis: 45%;
      align-items: flex-start;
      justify-content: center;
      text-align: left;

      .icon-con {
        text-align: left;
      }

      .headline-con {
        .headline {
          color: #fff !important;
          font-size: 26px !important;
        }
      }

      p {
        font-size: 16px !important;
        font-weight: 400 !important;
        margin-bottom: 0;
      }
    `}
`;

const IconContainer = ({ icon, highlight, headline, copy, ...props }) => {
  const ic = icon ? <GatsbyImage fixed={icon.childImageSharp.fixed} /> : <></>;
  return (
    <IconCon {...props}>
      <div className="icon-con">{ic}</div>
      <div className="headline-con">
        <h4 className="headline-highlight">{highlight}</h4>
        <h4 className="headline">{headline}</h4>
        <p dangerouslySetInnerHTML={{ __html: copy }}></p>
      </div>
    </IconCon>
  );
};
